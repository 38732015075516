import React, {useState, useEffect} from 'react';
import {EverythingContextProvider, useEverythingState} from '..//Contexts/EverythingContext'
import Moneylist from './Moneylist';
import Places from './Places';
import Cashes from './Cashes';

const Stats = (props) => {

    const everythingState = useEverythingState()
    var [filteredGames, setFilteredGames] = useState(everythingState)

    var historyTimeFilter = ['All Time', 'This Month']
    everythingState.forEach((game) => {
        const year = (new Date(game.gameDate).getFullYear())
        if (!historyTimeFilter.includes(year)) {
            historyTimeFilter.push(year)
        }
    })

    const filterResults = (filterBy) => {
        const today = new Date()
        const thisMonth = today.getMonth()
        const thisYear = today.getFullYear()

        var filteredList = []
        if (filterBy == 'All Time') {
            everythingState.forEach(game => {
                filteredList.push(game)
            })
        } else if (filterBy =='This Month') {
            everythingState.forEach(game => {
                var gameDate = new Date(game.gameDate)
                if (gameDate.getMonth() == thisMonth && gameDate.getFullYear() == thisYear) {
                    filteredList.push(game)
                }
            })
        } else {
            var year = parseInt(filterBy)
            everythingState.forEach(game => {
                var gameDate = new Date(game.gameDate)
                if (gameDate.getFullYear() == year) {
                    filteredList.push(game)
                }
            })
        }
        setFilteredGames(filteredList)

    }

    // handle Change Year filter
    const handleChangeYear = (e) => {
        filterResults(e.target.value)
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <select className="form-select form-select" onChange={handleChangeYear}>
                    {historyTimeFilter.map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            <div className="row">
                <div className="col" id="moneylist">
                    <Moneylist games={filteredGames}/>
                </div>
                <div className="col" id="cashes">
                    <Cashes games={filteredGames}/>
                </div>
                <div className="col" id="places">
                    <Places games={filteredGames}/>
                </div>
            </div>
        </div>
    );
}

export default Stats;