import React, {useState, useEffect} from 'react';
import {EverythingContextProvider, useEverythingState} from '..//Contexts/EverythingContext'
import Game from './Game'

const { DateTime } = require("luxon");

const History = () => {

    const everythingState = useEverythingState()
    var [filteredGames, setFilteredGames] = useState([...everythingState])

    var historyTimeFilter = ['All Time', 'This Month']
    everythingState.forEach((game) => {
        const year = (new Date(game.gameDate).getFullYear())
        if (!historyTimeFilter.includes(year)) {
            historyTimeFilter.push(year)
        }
    })

    function filterResults(filterBy) {
        const today = new Date()
        const thisMonth = today.getMonth()
        const thisYear = today.getFullYear()

        var results = []
        if (filterBy == 'All Time') {
            results = everythingState

        } else if (filterBy =='This Month') {
            everythingState.forEach(game => {
                var gameDate = new Date(game.gameDate)
                if (gameDate.getMonth() == thisMonth && gameDate.getFullYear() == thisYear) {
                    results.push(game)
                }
            })
        } else {
            var year = parseInt(filterBy)
            everythingState.forEach(game => {
                var gameDate = new Date(game.gameDate)
                if (gameDate.getFullYear() == year) {
                    results.push(game)
                }
            })
        }
        setFilteredGames(results)

    }

    // handle Add Player button click
    const handleChangeYear = (e) => {
        filterResults(e.target.value)
    }
        
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        Game History&nbsp;&nbsp;&nbsp;&nbsp;
                        <select className="form-select form-select" onChange={handleChangeYear}>
                            {historyTimeFilter.map((year, index) => (
                                <option key={year}>{year}</option>
                            ))}
                        </select>
                        &nbsp;&nbsp;&nbsp;&nbsp;{filteredGames.length} games

                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="accordion" id="gameList">

                            {filteredGames.map(game => (
                                <div className="card" key={game.id}>
                                    <div className="card-header" id={"#heading" + game.id} style={{padding:0}}>
                                        <h5>
                                            <button key={game.id} className="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + game.id}>
                                                {DateTime.fromISO(game.gameDate).toLocaleString(DateTime.DATE_HUGE)}  &nbsp;&nbsp;&nbsp; ({game.description}) &nbsp; {game.results.length != game.pot / game.buyin ? '(Incomplete Results)' : null}
                                            </button>
                                        </h5>
                                    </div>
                                    <div id={"collapse" + game.id} className="collapse" data-bs-parent="#gameList">
                                        <div className="card-body">
                                            <div>
                                                <Game gameData = {game} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default History;