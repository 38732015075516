import React, {useState, useEffect} from 'react';
import firestore from '../firebase'

const Places = (props) => {

    const games = props.games

    // Consolidate the player list with total winnings per player
    // First populate an array, reducedList, with total finishes for each place per player
    var reducedList = []
    var highestPlace = 0
    games.forEach(game => {
        game.results.forEach(result => {
            var found = false
            if (result.place > highestPlace) {

                highestPlace = result.place
            }
            reducedList.forEach(reducedListItem => {
                if (result.playerID == reducedListItem.playerID && result.place == reducedListItem.place) {
                    reducedListItem.times += 1
                    found = true
                }
            })
            if (!found) {
                result.times = 1
                reducedList.push(result)
            }
        })
    })

    // Then sort this array by place and number of times at that place
    reducedList.sort(function(a, b) {
        if (a.place < b.place) {
            return -1;
            }
            if (a.place > b.place) {
            return 1;
            }
            if (a.times > b.times) {
                return -1;
            }
            if (a.times < b.times) {
                return 1;
            }
            return 0;
    });

    // Last, remove all but the top place total for each place
    for (var place = 1; place <= highestPlace; place++) {
        var firstIndexOfThisPlace = reducedList.findIndex(result => result.place == place)
        var firstIndexOfNextPlace = reducedList.findIndex(result => result.place == place+1)
        if (firstIndexOfNextPlace == -1) {
            firstIndexOfNextPlace = reducedList.length
        }
        reducedList.splice(firstIndexOfThisPlace+1, firstIndexOfNextPlace - firstIndexOfThisPlace - 1)
    }

    var places = reducedList;

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    Player with most finishes in each place&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
            </div>

            <div className="col">
                <div className="row">
                    <div className="col">
                        <u>Place</u>
                    </div>
                    <div className="col">
                        <u>Player</u>
                    </div>
                    <div className="col">
                        <u>Times in this place</u>
                    </div>
                </div>

                {places.map((place, index) => 
                    <div key={place.playerID + index}>
                        <div className="row border">
                            <div className="col">{place.place}</div>
                            <div className="col">{place.player.handle}</div>
                            <div className="col">{place.times}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Places;