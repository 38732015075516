import React, {useState, useEffect} from 'react';

const Moneylist = (props) => {

    const games = props.games
    var [grossTotals, setGrossTotals] = useState(true)

    var players = []
    var totalMoney = 0
    games.forEach(game => {
        var gameWinnings = 0
        game.results.forEach(result => {
            gameWinnings += result.winnings
            if (grossTotals == false) {
                gameWinnings -= game.buyin
            }

            var found = false
            players.forEach(player => {
                if (player.playerID == result.playerID) {
                    found = true
                    player.winnings += result.winnings
                    if (grossTotals == false) {
                        player.winnings -= game.buyin
                    }
                }
            })

            if (!found) {
                const newPlayer = {
                    playerID : result.playerID,
                    handle: result.player.handle,
                    winnings : result.winnings
                }
                if (grossTotals == false) {
                    newPlayer.winnings -= game.buyin
                }

                players.push(newPlayer)
            }
        })
        totalMoney += gameWinnings
    })

    players.sort(function(player1, player2) {
        if (player1.winnings > player2.winnings) {
            return -1;
          }
          if (player1.winnings < player2.winnings) {
            return 1;
          }
          // a must be equal to b
          return 0;
    })
  
    const handleChangeNetGross = (e) => {
        if (e.target.value === 'Gross') {
            setGrossTotals(true)
        } else {
            setGrossTotals(false)
        }
    }

    function NetTotalsMessage() {
        if (grossTotals === false && totalMoney != 0) {
            return (
                <div className="row">
                    <div className="col">
                        <i><b>*</b> We failed to record all players for a few games. ${totalMoney} is the total buyin from unknown players.</i>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    Money List&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="radio">
                        <label>
                            <input type="radio" name="NetGross" value="Gross" checked={grossTotals === true} onChange={handleChangeNetGross} />
                            &nbsp;Gross
                        </label>
                        &nbsp;&nbsp;
                        <label>
                            <input type="radio" name="NetGross" value="Net" checked={grossTotals === false} onChange={handleChangeNetGross} />
                            &nbsp;Net{(grossTotals === false && totalMoney != 0) ? '*' : ''}
                        </label>
                    </div>
                </div>
            </div>

            <div className="col">
                <div className="row">
                    <div className="col">
                        <u>Player</u>
                    </div>
                    <div className="col">
                        <u>Total Winnings (${totalMoney})</u>
                    </div>
                </div>

                {players.map((player, index) => 
                    <div key={player.playerID + index}>
                        <div className="row border">
                            <div className="col">{player.handle}</div>
                            <div className="col">${player.winnings}</div>
                        </div>
                    </div>
                )}
            </div>

            <div>
                <NetTotalsMessage />
            </div>
        </div>
    )
}

export default Moneylist;