import React, { useState, useEffect, useContext } from "react";
import firestore from '../firebase'

const EverythingContext = React.createContext();

const { DateTime } = require("luxon");;

const EverythingContextProvider = ({children}) => {

    const [Games, setGames] = useState([])

    useEffect(() => {
        const fetchGames = () => {
            firestore.collection("games")
                .orderBy('gameDate', 'desc')
                .onSnapshot(querySnapshot => {
                    var allGames = [];

                    querySnapshot.forEach((game) => {
                        const jsDate = game.data().gameDate.toDate()
                        const luxonDate = DateTime.fromJSDate(jsDate)
                        var dateString = luxonDate.toString()

                        var gameComments = []
                        if (game.data().comments != undefined) {
                            gameComments = game.data().comments
                        }
                        var thisGameResults = []
                        var gamePot = 0
                        var thisGame = {}
                        game.ref.collection("results").get()
                            .then(gameResults => {
                                var promises = [];

                                gameResults.forEach(result => {
                                    promises.push(

                                        result.data().player.get()  // get this player
                                            .then(function(thisPlayer) {
                                                if (thisPlayer.exists) {
                                                    var newResult = {
                                                        playerID : thisPlayer.id,
                                                        player : thisPlayer.data(),
                                                        place: result.data().place,
                                                        winnings : result.data().winnings,
                                                    }
                                                    gamePot = gamePot + newResult.winnings
                                                    thisGameResults.push(newResult);
                                                }})
                                )});
    
                                Promise.all(promises).then(() => {
                                    thisGame.pot = gamePot
                                    if (thisGameResults.length <= 4) {
                                        if (game.data().description == "Turbo Game") {
                                            thisGame.buyin = 5
                                        } else if (game.data().description == "Main Game") {
                                            thisGame.buyin = 10
                                        }
                                    } else {
                                        thisGame.buyin = thisGame.pot / thisGameResults.length;
                                    }

                                    // Finished reading all results for this game, now sort them by place
                                    thisGameResults.sort(function(result1, result2) {
                                        if (result1.place < result2.place) {
                                            return -1;
                                            }
                                            if (result1.place > result2.place) {
                                            return 1;
                                            }
                                            // a must be equal to b
                                            return 0;
                                    })
                                })
                            });

                        thisGame.id = game.id;
                        thisGame.gameDate = dateString;
                        thisGame.description = game.data().description;
                        thisGame.comments = gameComments;
                        thisGame.results = thisGameResults;
                         
                        allGames.push(thisGame);
                    })
                setGames(allGames)
            });
        };

        fetchGames();
    }, [])
    
    return (
        <EverythingContext.Provider value={Games}>
            {children}
        </EverythingContext.Provider>
    )
}

function useEverythingState() {
    const context = React.useContext(EverythingContext)
    if (context === undefined) {
      throw new Error('useEverythingState must be used within a EverythingContextProvider')
    }

    return context
}

export {EverythingContextProvider, useEverythingState};