import React, {useState, useEffect} from 'react';
import firestore from '../firebase'
var Filter = require('bad-words')
 
const Game = (props) => {

    const thisGame = props.gameData
    const [gameResults, setGameResults] = useState(thisGame.results)
    const [comment, setComment] = useState("")

    function handleEditComment(e) {
        setComment(e.target.value)
    }

    function handleSubmitComment(e) {
        var filter = new Filter()

        thisGame.comments.push(filter.clean(comment))

        var gameRef = firestore.collection("games").doc(thisGame.id);
        return gameRef.update({
            comments: thisGame.comments
        })

        setComment("")
    }

    function UnknownPlayers () {
        const expectedPlayers = thisGame.pot / thisGame.buyin
        if (gameResults.length < expectedPlayers) {
            var unknownPlayerRows = []
            for (var playerIndex=gameResults.length + 1; playerIndex <= expectedPlayers; playerIndex++) {
                unknownPlayerRows.push(
                    <div className="row" key={playerIndex}>
                        <div className="col">{playerIndex}</div>
                        <div className="col">Not Recorded</div>
                        <div className="col">$0</div>
                    </div>
                )
            }

            return unknownPlayerRows;
        } else {
            return null
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    Pot: ${thisGame.pot},&nbsp;&nbsp;Buyin: ${thisGame.buyin},&nbsp;&nbsp;Players: {thisGame.pot / thisGame.buyin}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <u>Place</u>
                </div>
                <div className="col">
                    <u>Name</u>
                </div>
                <div className="col">
                    <u>Winnings</u>
                </div>
            </div>

            {gameResults.map((newResult, index) => 
                <div key={newResult.playerID+index}>
                    <div className="row">
                        <div className="col">{newResult.place}</div>
                        <div className="col">{newResult.player.handle}</div>
                        <div className="col">${newResult.winnings}</div>
                    </div>
                </div>
            )}

            <UnknownPlayers />

            <div className="row">
                <br/>Comments
            </div>
            <div className="row">
                <div className="col-md-8">
                    {thisGame.comments.map((comment, commentIndex) => 
                        <div>
                            <ul key={comment.slice(0, 25)}>
                                <li>
                                    <i>{comment}</i>
                                </li>
                            </ul>
                        </div>
                    )}

                    <div className="input-group">
                        <input type="text" className="form-control" onChange={handleEditComment}/>
                        <button type="button" className="btn btn-primary" onClick={handleSubmitComment}>+</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Game;