import React, {useState, useEffect} from 'react';
import {PlayersContextProvider, usePlayersState} from '..//Contexts/PlayersContext';
import {EverythingContextProvider, useEverythingState} from '..//Contexts/EverythingContext'

function DisplayPlayers() {
    const playersState = usePlayersState()
    return (
        <div>
            {
                playersState.map((player, playerIndex) => {
                    const eventKey = playerIndex
                    return (
                        <div>
                            {player.handle}
                        </div>
                    )
                })
            }
        </div>
    )
}

function DisplayEverything() {
    const everythingState = useEverythingState()
    return (
        <div>
            {
                everythingState.map((game, gameIndex) => {
                    const eventKey = gameIndex
                    return (
                        <div>
                            <div>
                                {game.id + " - " + game.gameDate + " - " + game.description}
                            </div>
                            <div>
                                {
                                    game.results.map((result, resultIndex) => {
                                        return (
                                            <div>
                                                {result.player.FirstName + " " + result.player.LastName}
                                            </div>
                                        )
                                })}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const Players = (props) => {

    return (
        <div>
            <h3>All Players</h3>
            <PlayersContextProvider>
                <DisplayPlayers />
            </PlayersContextProvider>
        </div>
    );
}

export default Players;