import React, { useState, useEffect, useContext } from "react";
import firestore from '../firebase'

const ResultsContext = React.createContext();
const { DateTime } = require("luxon");

const ResultsContextProvider = ({children}) => {

    const [Results, setResults] = useState([])

    useEffect(() => {
        var results = firestore.collectionGroup('results')
        var resultsList = []
        results.get()
        .then((querySnapshot) => {
            var promises = [];
            querySnapshot.forEach((result) => {
                result.data().player.get()  // get this player
                .then(function(thisPlayer) {
                    if (thisPlayer.exists) {

                        var newResult = {
                            playerID : thisPlayer.id,
                            player : thisPlayer.data(),
                            place: result.data().place,
                            winnings : result.data().winnings
                        }
                        resultsList.push(newResult);
                    } else {
                        console.log("No such player!");
                        }
                    }).catch(function(error) {
                        console.log("Error getting document:", error);
                    })
                })
                Promise.all(promises).then(() => {
                    setResults(resultsList)
                });
            });
    }, [])
    
    return (
        <ResultsContext.Provider value={Results}>
            {children}
        </ResultsContext.Provider>
    )
}

function useResultsState() {
    const context = React.useContext(ResultsContext)
    if (context === undefined) {
      throw new Error('useResultsState must be used within a ResultsContextProvider')
    }

    return context
}

export {ResultsContextProvider, useResultsState};


/*

                    // Consolidate the player list with total winnings per player

                    // First populate an array, reducedList, with total finishes for each place per player
                    var reducedList = []
                    var highestPlace = 0
                    resultsList.forEach(result => {
                        var found = false
                        if (result.place > highestPlace) {
                            highestPlace = result.place
                        }
                        reducedList.forEach(reducedListItem => {
                            if (result.playerID == reducedListItem.playerID && result.place == reducedListItem.place) {
                                reducedListItem.times += 1
                                found = true
                            }
                        })
                        if (!found) {
                            result.times = 1
                            reducedList.push(result)
                        }
                    })

                    // Then sort this array by place and number of times at that place
                    reducedList.sort(function(a, b) {
                        if (a.place < b.place) {
                            return -1;
                          }
                          if (a.place > b.place) {
                            return 1;
                          }
                          if (a.times > b.times) {
                              return -1;
                          }
                          if (a.times < b.times) {
                              return 1;
                          }
                          return 0;
                    });

                    // Last, remove all but the top place total for each place
                    for (var place = 1; place <= highestPlace; place++) {
                        var firstIndexOfThisPlace = reducedList.findIndex(result => result.place == place)
                        var firstIndexOfNextPlace = reducedList.findIndex(result => result.place == place+1)
                        if (firstIndexOfNextPlace == -1) {
                            firstIndexOfNextPlace = reducedList.length
                        }
                        reducedList.splice(firstIndexOfThisPlace+1, firstIndexOfNextPlace - firstIndexOfThisPlace - 1)
                    }
        
                    // Set the state
                    setPlaces(reducedList)


*/