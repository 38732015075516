import React, { useState, useEffect, useContext } from "react";
import firestore from '../firebase'

const GamesContext = React.createContext();

const { DateTime } = require("luxon");;

const GamesContextProvider = ({children}) => {

    const [Games, setGames] = useState([])

    useEffect(() => {
        const fetchGames = () => {
            firestore.collection("games")
                .orderBy('gameDate', 'desc')
                .onSnapshot(querySnapshot => {
                    var allGames = [];

                    querySnapshot.forEach((game) => {
                        const jsDate = game.data().gameDate.toDate()
                        const luxonDate = DateTime.fromJSDate(jsDate)
                        var dateString = luxonDate.toString()

                        var thisGame = {
                            id: game.id,
                            gameDate: dateString,
                            description: game.data().description,
                            results: game.data().results
                            
                        }
                        allGames.push(thisGame);
                    })
                setGames(allGames)
            });
        };

        fetchGames();
    }, [])
    
    return (
        <GamesContext.Provider value={Games}>
            {children}
        </GamesContext.Provider>
    )
}

function useGamesState() {
    const context = React.useContext(GamesContext)
    if (context === undefined) {
      throw new Error('useGamesState must be used within a GamesContextProvider')
    }

    return context
}

export {GamesContextProvider, useGamesState};