import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Components/home";
import History from "./Components/history";
import Stats from "./Components/stats";
import Players from "./Components/players";
import NewGame from './Components/NewGame';
import pumalogo from './resources/pumacirclelogo.png';

import { BrowserRouter, HashRouter, NavLink, Route, Switch } from 'react-router-dom';
import {PlayersContextProvider} from './Contexts/PlayersContext.js'
import {GamesContextProvider} from './Contexts/GamesContext';
import {ResultsContextProvider} from './Contexts/ResultsContext';
import { EverythingContextProvider } from './Contexts/EverythingContext';

function App() {

  return (
      <HashRouter>
        <div>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h1>PAWs Poker</h1>
              </div>
              <div className="col">
                <img className="float-right" width="50" height="50" src={pumalogo}/>
              </div>
            </div>
          </div>

          <ul className="header">
            <li><NavLink exact to="/">Home</NavLink></li>
            <li><NavLink to="/history">History</NavLink></li>
            <li><NavLink to="/stats">Stats</NavLink></li>
            <li><NavLink to="/players">Players</NavLink></li>
          </ul>
          <div className="container-fluid">
            <div className="content row">
              <EverythingContextProvider>
                    <div className="col">
                      <Route exact path="/" component={Home}/>
                      <Route path="/history" component={History}/>
                      <Route path="/stats" component={Stats}/>
                      <Route path="/players" component={Players}/>
                      <PlayersContextProvider>
                        <Route path="/admin" component={NewGame}/>
                      </PlayersContextProvider>
                    </div>
              </EverythingContextProvider>
            </div>
          </div>
        </div>
      </HashRouter>
  );
}

export default App;