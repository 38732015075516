import React, {useState, useEffect} from 'react';
import {PlayersContextProvider, usePlayersState} from '..//Contexts/PlayersContext';
import {EverythingContextProvider, useEverythingState} from '..//Contexts/EverythingContext'


function Cashes (props) {

    const games = props.games

    var players = []
    games.forEach(game => {
        game.results.forEach(result => {

            var found = false

            players.forEach(player => {
                if (player.playerID == result.playerID) {
                    found = true
                    player.games += 1
                    if (result.winnings > 0) {
                        player.cashes += 1
                    }
                    player.efficiency = Math.round(player.cashes/player.games*100)
                }
            })

            if (!found) {
                const newPlayer = {
                    playerID : result.playerID,
                    handle: result.player.handle,
                    games : 1,
                    cashes: 0
                }
                if (result.winnings > 0) {
                    newPlayer.cashes = 1
                }
                newPlayer.efficiency = Math.round(newPlayer.cashes/newPlayer.games*100)


                players.push(newPlayer)
            }
        })
    })

    players.sort(function(player1, player2) {
        if (player1.efficiency > player2.efficiency) {
            return -1;
          }

          if (player1.efficiency < player2.efficiency) {
            return 1;
          }

          // equal
          return 0;
    })
  
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    Cashes&nbsp;&nbsp;&nbsp;&nbsp;({games.length} games)
                </div>
            </div>

            <div className="col">
                <div className="row">
                    <div className="col">
                        <u>Player</u>
                    </div>
                    <div className="col">
                        <u>Cashes/Games Played (%)</u>
                    </div>
                </div>

                {players.map((player, playerIndex) => 
                    <div key={player.playerID + playerIndex}>
                        <div className="row border">
                            <div className="col">{player.handle}</div>
                            <div className="col">{player.cashes}/{player.games}&nbsp;&nbsp;({Math.round(player.cashes/player.games*100)}%)</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Cashes;