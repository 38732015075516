import React, {useState, useEffect} from 'react';
import {PlayersContextProvider, usePlayersState} from '..//Contexts/PlayersContext'
import firestore from '../firebase'
import firebase from 'firebase/app'

const NewGame = (props) => {

    const blankGamePlayer = {
        place: 0,
        winnings: 0,
        player: {
            id: 0,
            firstName: "",
            lastName: ""
        }
    }

    const Players = usePlayersState()

    const [gameDate, setGameDate] = useState("2021-02-01")
    const [gamePlayers, setGamePlayers] = useState ([])
    const [gameDescription, setGameDescription] = useState ("")

    const handleDateChange = e => {
        setGameDate(e.target.value)
    }

    const handleGameTypeChange = e => {
        setGameDescription(e.target.value)
    }

    // handle Add Player button click
    const handleAddPlayer = () => {
        var newPlayer = blankGamePlayer
        newPlayer.place = gamePlayers.length + 1
        const newGamePlayers = [...gamePlayers, newPlayer]
        setGamePlayers(newGamePlayers)
    }

    // handle Add Player button click
    const handleChoosePlayer = (e) => {
        const params = e.target.value.split(",")
        const gamePlayerIndex = Number(params[0])
        const playerChosen = Number(params[1])

        const currentGamePlayers = gamePlayers
        currentGamePlayers[gamePlayerIndex].player = Players[playerChosen]
        setGamePlayers(currentGamePlayers)
    }

    // handle Add Player button click
    const handleChangeWinnings = (e) => {
        const playerIndex = e.target.id
        const currentGamePlayers = gamePlayers
        currentGamePlayers[playerIndex].winnings = parseInt(e.target.value)
        setGamePlayers(currentGamePlayers)
    }
    
    const handleFormSubmit = e => {
        e.preventDefault();

        // Convert the gamedate to a firestore Timestamp
        let JSgameDate = Date.parse(gameDate + "T00:00:00")
        const gameDateTimestamp = new firebase.firestore.Timestamp.fromMillis(JSgameDate);

        // Add a new game in collection "games"
        var newGameRef = firestore.collection('games').doc()
        newGameRef.set({
            gameDate: gameDateTimestamp,
            description: gameDescription
        })
        .then(function() {
            var promises = []
            const currentGamePlayers = gamePlayers;
            currentGamePlayers.forEach((gamePlayer) => {
                promises.push (
                    newGameRef.collection('results').add({
                        place: gamePlayer.place,
                        player: firestore.doc('/players/' + gamePlayer.player.id),  // This really should be a document reference to this player
                        winnings: gamePlayer.winnings
                    })
                )
                Promise.all(promises).then(() => {
                    setGameDate("2020-02-01")
                    setGamePlayers ([])
                
                })
            })
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <form className="mt-6" onSubmit={handleFormSubmit}>
                    <div className="col">
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Game Date</span>
                            </div>
                            <input className="form-control" type="date" value={gameDate} id="example-date-input"
                                name="gameDate"
                                onChange={handleDateChange} />
                        </div>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Description</span>
                            </div>
                            <input type="text" className="form-control" id={'description'} onChange={handleGameTypeChange}/>
                        </div>

                    </div>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={handleAddPlayer}>Add Player</button>
                    </div>

                    {gamePlayers.map((gamePlayer, gamePlayerIndex) => {
                        return (
                            <div className="container">
                                <div className="form-group row">
                                    <div className="col-xs-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Place</span>
                                            </div>
                                            <input type="text" disabled value={gamePlayerIndex + 1} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Winnings</span>
                                            </div>
                                            <input type="text" className="form-control" id={gamePlayerIndex} onChange={handleChangeWinnings}/>
                                        </div>
                                    </div>
                                    <PlayersContextProvider>
                                        <div className="col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Player</span>
                                                </div>
                                                <select className="form-select form-select" onChange={handleChoosePlayer}>
                                                    <option key="Choose this player" value="-1">Choose this player</option>
                                                    {Players.map((player, playerIndex) => {
                                                        const eventKey = gamePlayerIndex + "," + playerIndex
                                                        return (
                                                            <option key={player.FirstName + player.LastName} value={eventKey}>{player.FirstName} {player.LastName}</option>
                                                    )})}
                                                </select>
                                            </div>
                                        </div>
                                    </PlayersContextProvider>
                                </div>
                            </div>
                        )})}

                    <div>
                        <input type="submit" value="Save Game" className="btn btn-primary" />
                    </div>

                </form>
            </div>
        </div>
    );
}

export default NewGame;