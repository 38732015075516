import firebase from 'firebase/app'
import "firebase/firestore"

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyCYRLYk6QR9-Q0PFKYNGbceaHbOwhMENfA",
  authDomain: "friendstats-4fd15.firebaseapp.com",
  projectId: "friendstats-4fd15",
  storageBucket: "friendstats-4fd15.appspot.com",
  messagingSenderId: "763446216950",
  appId: "1:763446216950:web:92010162e9d6e5b10904d7"  };

// Initialize Firebase
let firebaseApp = firebase.initializeApp(firebaseConfig);
let firestore = firebaseApp.firestore()

export default firestore;