import React, { useState, useEffect, useContext } from "react";
import firestore from '../firebase'

const PlayersContext = React.createContext();

const PlayersContextProvider = ({children}) => {

    const [Players, setPlayers] = useState([])

    const fetchPlayers = () => {
        var allPlayers = []
        firestore.collection("players")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const player = {
                        id: doc.id,
                        FirstName: doc.data().FirstName,
                        LastName: doc.data().LastName,
                        handle: doc.data().handle
                    }
                    allPlayers.push(player)
                })
                allPlayers.sort(function(player1, player2) {
                    if (player1.handle < player2.handle) {
                        return -1;
                      }
                      if (player1.handle > player2.handle) {
                        return 1;
                      }
                      // a must be equal to b
                      return 0;
                })
                setPlayers(allPlayers)
            
            });
    };

    useEffect( () => {
        fetchPlayers()
    },[])
    
    return (
        <PlayersContext.Provider value={Players}>
            {children}
        </PlayersContext.Provider>
    )
}

function usePlayersState() {
    const context = React.useContext(PlayersContext)
    if (context === undefined) {
      throw new Error('usePlayersState must be used within a PlayersContextProvider')
    }

    return context
}

export {PlayersContextProvider, usePlayersState};