import React, {useState, useEffect} from 'react';


const Home = (props) => {

    return (
        <div>
            <h3>Home</h3>

            <div>
                <h3>Coming Soon...</h3>
                <ul>
                    <li>Recent Results</li>
                    <li>Comment Feed</li>
                    <li>Gross vs Net</li>
                    <li>Stats highlights</li>
                        <ul>
                            <li>Most Wins</li>
                            <li>Fewest Wins</li>
                            <li>Money Leader</li>
                            <li>Money Loser</li>
                        </ul>
                    <li>Schedule</li>
                </ul>
            </div>
        </div>
    );
}

export default Home;